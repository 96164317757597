.banner-user,
.banner-anim {
  height: 300px;
}
.banner-anim-elem.banner-user-elem {
  color: #fff;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.banner-thumb-bottom .banner-anim-elem {
  height: 560px;
}
.banner-thumb-bottom .banner-anim-thumb {
  background: #999;
}
.banner-anim-elem .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.text-wrapper {
  color: #fff;
  text-align: center;
  margin-top: 100px;
}
.text-wrapper h1,
.text-wrapper h2 {
  color: #E20074;
  font-weight: lighter;
}
.info{
  max-width: 926px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.info h1 {
   color: #E20074;
    font-weight: lighter;
  font-size: 36px;
  margin-bottom: 18px;

}
.info h2 {
   color: #fff;
  font-weight: lighter;
  font-size: 18px;
  margin-bottom: 18px;
  text-shadow: 1px 1px 4px #000000;
}
.info p {
   color: #cacaca;
  font-weight: lighter;
  font-size: 18px;

}
.icon-wrapper {
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.icon-wrapper > div {
  position: absolute;
}
.icon-wrapper > div:nth-child(1) {
  left: 10%;
  top: 20%;
}
.icon-wrapper > div:nth-child(2) {
  left: 40%;
  top: 70%;
}
.icon-wrapper > div:nth-child(3) {
  right: -20px;
  bottom: 80px;
}
.banner-anim {
  position: relative;
  overflow: hidden;
  min-height: 100px;
}
.banner-anim-elem {
  height: 100%;
}
.banner-anim-elem > * {
  position: relative;
}
.banner-anim-elem-mask {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.banner-anim-arrow {
  cursor: pointer;
  position: absolute;
  z-index: 10;
}
.banner-anim-arrow-default {
  position: absolute;
  width: 30px;
  height: 30px;
  margin-top: -30px;
  top: 50%;
   border-radius: 30px;
  background: rgba(0, 0, 0, 0.3);
  margin: 15px;
}
.banner-anim-arrow-default.next {
  right: 0;
}
.banner-anim-arrow-default.next:before,
.banner-anim-arrow-default.prev:before,
.banner-anim-arrow-default.next:after,
.banner-anim-arrow-default.prev:after {
  width: 2px;
  height: 10px;
  background: #E20074;
  display: block;
  content: ' ';
  position: absolute;
}
.banner-anim-arrow-default.next:before {
  transform: rotate(-40deg);
  top: 7px;
  left: 15px;
}
.banner-anim-arrow-default.next:after {
  transform: rotate(40deg);
  bottom: 7px;
  left: 15px;
}
.banner-anim-arrow-default.prev:before {
  transform: rotate(40deg);
  top: 7px;
  left: 14px;
}
.banner-anim-arrow-default.prev:after {
  transform: rotate(-40deg);
  bottom: 7px;
  left: 14px;
}
.banner-anim-thumb {
  position: absolute;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  pointer-events: none;
  z-index: 10;
}
.banner-anim-thumb > span {
  pointer-events: auto;
  cursor: pointer;
  display: inline-block;
  list-style: none;
}
.banner-anim-thumb-default {
  height: 40px;
  line-height: 40px;
}
.banner-anim-thumb-default span {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin: 0 5px;
  background: rgba(102, 102, 102, 0.35);
  transition: background .3s;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
}
.banner-anim-thumb-default span:active,
.banner-anim-thumb-default span.active {
  background: #E20074;
}