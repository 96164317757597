/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
}
.ripple:active {
  background-color: #6eb9f7;
  background-size: 100%;
  transition: background 0s;
}
.flex-container {
    box-sizing: border-box; 
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    position:relative;
    margin-top: 8px;
    max-width: 1138px;
}
.featured-videos {
    box-sizing: border-box; 
    margin-left: 8px;
    position:relative;
}
.featured-video-card-thumb {
    box-sizing: border-box; 
    position: relative;
    display: block;
    max-width:100%;
}
.feature-video-card {
    box-sizing: border-box; 
    position: relative;
    border-top: 2px solid transparent;
    width: 100%;
    min-height: 100%;
}
.feature-video-card-overlay {
    box-sizing: border-box; 
    background-color: rgba(0,0,0,.47);
    position: absolute;
    width: 100%;
    padding: 8px;
    padding-bottom: 0px;
    display: block;
    font-family: 'Sprint Sans Web';
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}
.feature-video-card-overlay .feature-video-channel {
    margin: 0;
    font-size: 14px;
    font-weight: medium;
    margin-right: .66em;
}
.feature-video-card-overlay .feature-video-details {
    margin: 0;
    font-size: 12px;
    font-family: 'Sprint Sans Web';
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}
.feature-video-card-overlay .feature-video-card-text {
    max-width: 378px;
}
.feature-video-card-overlay h1 {
    margin: 0;
    font-size: 20px;
    font-family: 'Sprint Sans Web';
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}
.feature-video-duration {
    background-color: rgba(0,0,0,.57);
    padding:4px 10px 3px 10px;
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 12px;
    line-height: 12px;
    font-family: 'Sprint Sans Web';
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}
.drop-shadow-text {
    filter: drop-shadow(1px 2px 2px rgba(0,0,0,.5));
}