.videoCard:hover, .feature-video-card:hover {
  background-color: rgb(40,40,40);
  cursor:pointer;
  border-top:2px solid #E20074;
}
.videoCard:hover .videoThumbnailMenu {
  display:block;
}
.videoCard h2 {
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom:0px;
}
.metaDateAge {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  color: #95989A;
  margin-bottom: 6px;
}
.thumbWrapper {
  position: relative;
}
.thumbVideoDuration {
  position: absolute;
  right:0;
  bottom:0;
  margin:0;
}
.thumbWrapper {
  margin:0;
}
.videoCardMetadata {
  padding:0px 2px;
  padding-top: 4px;
  position:relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  width:100%;
}
.thumbnailTitle {
  width:190px;

}
.videoThumbnailMenu {
  fill: gray;
  width: 24px;
  height: 24px;
  margin-left: 2px;
  position: absolute;
  top: 0;
  right: 0;
  display:none;
}
.videoThumbnailMenu:hover {
  fill: white;
}    
div.videoCardMetadata div {
  order: 0;
  flex: 0 1 auto;
  align-self: stretch;
}
.thumbnailTitle {
  font-size: 15px;
  line-height: 18px;  
  font-weight: 400;  
  height: 36px;
  max-height: 36px;
  overflow: hidden;
  margin-bottom: 8px;
  color: #D0D0D0;
}
.videoCard:hover > .videoCardMetadata > .thumbnailTitle {
  color: white;
}    
.videoThumbnailMenu:hover ~ .thumbnailTitle {
  color: #D0D0D0 !important;
}
.videoThumbRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  margin-left: 8px;
}
.videoCard {
 
  vertical-align: text-top;
  border-top:2px solid transparent;
  position:relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  margin-top: 0;
}
.thumbnailContentBlock .videoCard:last-of-type {
  margin-right: 0px;
}
.contentHeader {
  margin-left: 8px;
  margin-bottom: 8px;
}
h2.contentHeader {
  font-size: 20px;
  line-height: 20px;      
  font-weight: 100;
  margin-top:10px;
  margin-bottom: 0px;
  color:white;
}
.thumbnailContentBlock {
  font-style:normal;
  font-stretch:normal;
  font-family:'Sprint Sans Web';
  /*max-width: 1124px;*/
  max-width: 1150px;
}
.thumbnailContentBlock > .contentHeader {
  padding-top:0px;
  margin-top: 12px;
  border-top: 2px solid #E20074;
  max-width: 1124px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
}
.thumbnailWrapper .thumbnailContentBlock:first-of-type .contentHeader {
  border-top: 2px solid transparent;
  margin-top: 12px;
}
.thumbVideoDuration {
  background-color: rgba(0,0,0,.57);
  padding:3px 6px 3px 6px;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 10px;
  line-height: 10px;
  font-weight: 300;
  color:white
}
.channelTitle {
  font-size: 13px;
  line-height: 13px;  
  font-weight: 400;
  color: #95989A;
}
div.channelLink {
  display:flex;
  flex-direction: row;
  align-items:center;
  margin-left:2px;
  
  
}   
.channelLink > svg {
  fill:gray;
  width:24px;
  height:24px;
  margin-right:5px;
}    
.channelLink:hover {
  text-decoration: underline;
}
.channelLink:hover > svg.channelIcon, .channelLink:hover > span.channelTitle {
  color:white;
  fill:white;
}
.thumbnailImg {
  width:220px;
  height:124px;
}
.thumbnailImg2 {
  width:110px;
  height:62px;
}
.activity-date {
  color: #95989A;
  margin-top: 8px;
}