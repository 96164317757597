

.dropzone {
  text-align: center;
  padding: 20px;
  border: 1px solid #eeeeee;
  background-color: #272727;
  color: #bdbdbd;
  font-size: 20px;
  margin-bottom: 20px;
}

.accept {
  border-color: #107c10 !important;
}

.reject {
  border-color: #d83b01 !important;
}
