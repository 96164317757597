.history-wrapper {
  color: #ACACAC;
  font-weight: 100;
  font-family: sans-serif, Helvetica;
  margin: 8px;
  margin-left: 0;
}
div.history-divider {
  height: 1px;
  background-color: rgba(255, 206, 10, 0.3);
}
div.history-wrapper > div.history-divider:last-of-type {
    display: none;
}
.history-row {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 100px 0px 2fr 0px 1fr 0px 1fr;
  grid-template-columns: 100px 2fr 1fr 1fr;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  justify-items: start;
  padding: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
  display: border-box;
  height: 100%;
}
.history-row > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
.history-row > *:nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
}
.history-row > *:nth-child(3) {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
}
.history-row > *:nth-child(4) {
  -ms-grid-row: 1;
  -ms-grid-column: 7;
}
    
.history-row > li {
  /* Adding Flex as a hack to get content to center in IE11*/
  display: flex;
  -ms-flex-direction: column;
  flex-direction: row;
}
.history-row > li::before,
.history-row > li::after {
  content: "";
  flex: 1;
}
.history-row img {
  width: 82px;
  height: 46px;
  display: block;
}
ul.history-row > li > svg:nth-child(2), .history-svg-wrapper > svg:nth-child(2) {
  fill: rgba(256, 0, 116, 1);
  display: inline-block;
  width:24px;
  height:24px
}
ul.history-row > li > svg:nth-child(2), .history-svg-wrapper > svg:nth-child(1) {
  fill: rgba(256, 0, 116, 1);
  display: inline-block;
  width:24px;
  height:24px
}
.history-svg-icon {
  fill: rgba(256, 0, 116, 1);
  display: inline-block;
  width:24px;
  height:24px
}
ul.history-row > li {
  list-style-type: none;
}
div.history-wrapper > ul.history-row:nth-child(4n+1) {
  background-color: #1f1f1f;
}
div.history-wrapper > ul.history-row:hover {
  background-color: #272727;
  cursor: pointer;
}
.history-row:hover li {
  color: white;
}