.new-playlist {
  font-size: 14px;
  padding: 4px 10px;
  background-color: #272727;
  color: #ccc;
  border: 2px solid #E20074;
  border-radius: 10px; 
  margin-left: 12px;
  height: 33px;
}
.new-playlist:hover {
    background-color: #E20074;
    color: black;
    cursor: pointer;
}
.playlist-controls-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: center;
    }

.flex-item:nth-child(1) {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    }

.flex-item:nth-child(2) {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    }