.channel-no--padding {
        /*background-image: url(images/channel/banner.png);*/
        background-position: left;
        background-repeat: no-repeat;
        height: 267px;
        position: relative;
        padding: 0;
        margin-left: 15px;
      }
      .channel-icon{
        width:160px;
        height:160px;
      }
      .channel-description {
          max-width: 580px;
      }
      .channel-description-outer-wrapper {
        position: relative;
        padding: 15px 40px;
        margin-left: 0;
      }
      .channel-banner-footer {
        /*position: absolute;
        bottom: 0;*/
        background-color: rgba(0,0,0,.5);
        width: 100%;
      }
      .channel-banner-footer h1 {
        display: inline-block;
        color: white;
        margin: 0;
        font-size: 36px
      }
      .podcast-subscribe-button {
        border-radius: 25px;
        border: 2px #E20074 solid;
        background-color: rgba(0,0,0,.5);
        color: white;
        font-weight: 400l;
        padding: 4px 30px;
        font-size: inherit;
      }
      .podcast-subscribe-button:hover {
        border: 2px #E20074 solid;
        background-color: rgba(0,0,0,.9);
        color: #E20074;
        cursor: pointer;
      }

      .channel-subscribe-button {
        border-radius: 25px;
        border: 2px #E20074 solid;
        background-color: rgba(0,0,0,.5);
        color: white;
        padding: 4px 35px;
        margin-bottom: 0;
      }
      .channel-subscribe-button:hover {
        border: 2px #E20074 solid;
        background-color: rgba(0,0,0,.9);
        color: #E20074;
        cursor: pointer;
      }
      .table-cell-left {
        display: table-cell;
        vertical-align: middle;
        text-align: left;
        padding-left: 30px;
      }
      .channel-created-date {
        color: white;
        font-size: 16px;
      }
      .channel-logo-wrapper {
        padding-right: 30px;
      }
      .channel-details {
        padding-right: 12px;
        font-size: 16px;
        margin: 0;        
      }
      .channel-feature-details {
        color: #ADADAD;
        font-size: 15px;
      }
      .dot-spacer:after {
        content: "•";
        padding-left: 1em;        
        padding-right: 1em;
      }
      .channel-feature-title h1 {
        font-size: 30px;        
      }
      
       h1.channel-feature-title {
        font-size: 1.875rem;       
      }      
      
      .channel-feature-wrapper {
        padding: 20px 50px;
      }
      .channel-feature-video-duration {
        background-color: rgba(0,0,0,.57);
        padding:4px 10px 3px 10px;
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 12px;
        line-height: 12px;
        font-family: 'Sprint Sans Web';
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
      }
      .channel-feature-table {
        display: table;
        display: inline-block;
        position: relative;
        width: 100%;
        padding-bottom: 20px;        
        border-bottom: solid 2px #E20074;
      }
      .channel-description-wrapper {
        display: table-cell;
        display: inline-block;

      }
      .channel-feature-description p {        
        color: white;
      }
      .channel-feature-description p:last-child {
        margin-bottom: 0;
      }
      .channel-feature-thumbnail {
        position: relative;
        margin-right: 12px;
        max-width: 452px;
        display: inline-block;
        float: left;
      }
      .channel-feature-component {
        display: inline-block; 
        border-bottom: solid 2px #E20074;
      }
      .channel-feature-description-wrapper {
        padding-left: 12px; 
        display: inline-block; 
        max-width: 550px;
        float: left;
        /*position: absolute; 
        bottom: 0;
        right: 0;        
        margin-top: auto;
        margin-bottom: 0;*/
      }
      .channel-playlist-description-container {
        max-width: 800px;
      }
      .channel-feature-thumb {
        position: relative;
      }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          #top-header {
          box-shadow: 0px -2px 15px 8px rgba(0,0,0,0.75);
         }
      }
      .channel-details span:after {
        content: "•";
        padding-left: .5rem;
        padding-right: .5rem;
      }
      .channel-details span:last-child:after {
        content: "";
        padding-left: 0;
      }
      .max-w {
        /*max-width: 1024px;*/
      }
      
      /* playlists */
      .channel-playlist-description-container {
        max-width: 800px;
      }
      /*
      .channel-feature-wrapper div { outline: 1px solid red; }
      */
      div.video-playlist-header-title {
        position: relative;
      }
      div.video-playlist-header-title > svg, div.channel-videos-header-title > svg {
        line-height: 2rem;
      }
       h1.video-playlist-header, h1.channel-videos-h1 {
        font-size: 32px;
        line-height: 32px;
        display: inline-block;
        margin-left: 6px;
        margin-right: 32px;
        margin-bottom: 0;
      }
      .sort {
        line-height: 2rem;
      }
      .sort-btn {
        border: 3px solid #E20074;
        background-color: white; 
        border-radius: 8px;
        color: gray;        
      } 
      .sort-btn > span {
        padding: 2px 6px;
      }
      .sort-btn > span.sort-active {
        background-color: #E20074;
      }
      .playlist-sort-icon, .channel-videos-sort-icon {
        width: 93px;
        height: 33px;
        display: inline;
        position: relative; 
        cursor: pointer;
      }
      .channel-videos-header {
        margin-bottom: 12px;
      }
      .channel-videos-header > div {
        display: inline-block;
        vertical-align: middle;
      }
      .video-playlist-header-title, .channel-videos-header-title {
        width: 232px;
      }
      .playlist-title-icon-playlist, .channel-videos-title-icon {
        width: 30px;
        height: 32px;
        fill: white;
        display: inline-block;
        vertical-align: middle;
      }
      .video-playlist-outer-container, .channel-videos-outer-container, footer {
        padding-left: 50px;
      }
      /*
      .video-playlist-description-header svg {
        margin-left: 12px;
        margin-right: 3px;
        fill: gray;
        width: 25px;
        height: 25px;
      }
      */
      svg.favorite {
        fill: #E20074;
      }
      div.channel-page > div.video-playlist-thumbnail  > div.playlist-thumbnail-tile {
        margin-right: 12px;
      }
      div.row.video-playlist-outer-container > div:last-child {
        border-bottom: 2px solid #E20074;
        margin-bottom: 20px;
      }
      p.copyright-footer {
        color: #727272;
        font-size: 12px;
      }
      .video-playlist-container {
        margin-bottom: 24px;
      }
      .channel-feature-thumb {
        position: relative;
        margin: 24px;
        margin-left: 0;
      }
      .channel-playlist-description-container > p {
        font-size: .9rem;
        font-weight: 300;
        padding-bottom: 0;
        margin-bottom: 0;
      }
      .playlist-options > span {
        white-space: nowrap;
        color: lightgray;
      }
      .playlist-options > span:hover {
        cursor: pointer;
        color: white;
      }
      .playlist-options > span > svg {
        fill: gray;
        width: 25px;
        height: 25px;
      }
      .playlist-options > span > svg.favorite {
        fill: #E20074;
        width: 25px;
        height: 25px;
      }
      .video-playlist-description-details > span {
        color: gray;
        font-size: .75rem;
      }
      .video-playlist-description-details > span:after {
        content: "|";
        margin-right: .8rem;
        margin-left: .8rem;
      }
      .video-playlist-description-details > span:first-child:before {
        margin-left:0;
      }
      .video-playlist-description-details > span:last-child:after {
        content: "";
        margin-right:0;
      }
      .bb-yellow {
        border-bottom: 2px solid #E20074;
      }
      div.thumbnail-tile-meta-container > h4 > span.views:after {
        content: "•";
        margin-left: .5rem;
        margin-right: .5rem;
      }