div.channel-container {
  display: flex;
  flex-wrap: wrap;
  color: white;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 4px;
  max-width: 1052px;
}
div.channel-container:hover {
  background-color: #272727;
  cursor: pointer;
} 
div.channel-thumbnail {
}
div.channel-thumbnail img {
  width: 220px;
  display: block;
}
div.channel-text {
  flex: 1;
  margin-left: 8px;
}
div.channel-button {
  margin-left: 8px;
  align-self: center;
  justify-content: flex-end;
}
div.channel-text > .channel-title {
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: 0.4em;
}
div.channel-text > h3 {
  vertical-align: top;
  margin: 0;
  margin-bottom: 10px;
  font-weight: 100;
  font-size: 24px;
}
div.channel-text > h5 {
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 1.4em;
  font-size: 14px;
  font-weight: 100;
}
div.channel-text > p {
  margin: 0;
  line-height: 1.6em;
  font-size: 15px;
}
div.channel-button > button {
  border-radius: 25px;
  border: 2px #E20074 solid;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 4px 35px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
}
div.channel-button > button:hover {
  cursor: pointer;
  background-color: black;
  color: #E20074;
}
div.channels-container > div:last-of-type > div.channel-divider {
/* This is a bit of a hack to get the last divider to hide in Chrome
   where you can't use :last-of-type on a class. */
  display: none;
}

/*
@media screen and (max-width: 790px) {
  div.channel-text {
    max-width: 583px;
  }
  div.channel-button {
    align-self: end;
  }
  div.channel-container {
  }
}
*/

/* 
@media screen and (max-width: 600px) {
  div.channel-container {
  }
  div.channel-text {
  }
  div.channel-button {
    justify-self: end;
  }
}
*/
div.channel-divider {
  background-color: #E20074;
  background-color: rgba(255, 206, 10, 0.3);
  height: 1px;
  margin: 6px 0px;
  max-width: 1024px;
  max-width: 1052px;
}
div.channels-container > div:last-of-type {
  display: none;
}