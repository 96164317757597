button.buttonPrimary {
  border-radius: 25px;
  border: 2px #E20074 solid;
  background-color: #E20074;
  color: black;
  padding: 3px 25px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  margin-right: 30px;
  text-transform: capitalize;
}
button.buttonPrimary:hover {
  cursor: pointer;
  background-color: black;
  color: #E20074;
  border: 2px #E20074 solid;
}
button.buttonCancel {
  border-radius: 25px;
  border: 2px gray solid;
  background-color: gray;
  color: black;
  padding: 3px 25px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}
button.buttonCancel:hover {
  cursor: pointer;
  background-color: black;
  color: #E20074;
  border: 2px #E20074 solid;
}
/*
input[type=text], textarea {
  -webkit-transition: all 0.30s ease-in-out;
  -moz-transition: all 0.30s ease-in-out;
  -ms-transition: all 0.30s ease-in-out;
  -o-transition: all 0.30s ease-in-out;
  outline: none;
  padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;
  border: 1px solid #E20074;
}

input[type=text]:focus, textarea:focus {
  box-shadow: 0 0 5px rgba(255, 206, 10, 1);
  padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;
  border: 1px solid rgba(255, 206, 10, 1);
}
*/