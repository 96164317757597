body {
  margin: 0;
}
.player-page-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: black 1px solid;
  color: white;
  font-family: "Sprint Sans";
  max-width:1200px;
}
.player-column {
  /**/
  max-width: 854px;
  flex: 1;
  min-width: 480px;
}
.player-wrapper {
  padding-top: 56%;
}
.player-date-options {
  display: flex;
  flex-direction: row;
  padding: 8px 12px 0px 12px;
}
.player-options {
  display: flex;
  flex-direction: row;
}
.player-date {
  flex: 1;
}
.player-date > h3 {
  color: #ACACAC;
  font-size: 16px;
}
.player-metadata {
  padding: 12px;
}
.player-metadata > h1, h2, h3, h4 {
  margin: 0;
  padding: 0;  
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
}
.player-keywords {
  /*
  border-top: 2px solid #E20074; */
  padding: 0;
  margin: 0 12px;
  padding-top:6px;
 
}
.player-keywords li {
  list-style: none;
  display: inline;
  margin: 0;
  padding-top: 10px;
  padding-bottom:20px;
  color: #8EBDFF ;
  font-size: 14px;
}
.player-keywords li::after {
  content: ", ";
}
.player-keywords li:last-child::after {
content: "";
}

.channel-tile-wrapper {
  display: flex;
  flex-direction: row;
}
.channel-tile {
  margin: 12px;
}
.deactivated {
width: 100%;
height: 400px;
position: relative;
background: rgb(81,37,62);
background: radial-gradient(circle at 50% 40%, rgba(81,37,62,1) 11%, rgba(40,40,40,1) 51%);
} 

.videoTranscodingBox{
   position: relative;
   display: inline-block; 
}

.videoTranscodingBoxText{
   position: absolute;
        z-index: 999;
        color:#E20074;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 40%; /* Adjust this value to move the positioned div up and down */
        text-align: center;
        width: 60%;
        font-weight: bold;
}

.video-description a{
  font-weight:bold;
  color: white;
  text-decoration: underline;
}

.video-description a:hover{
 
  color: #E20074;
 
}