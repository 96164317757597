body {
  background-color: black;
}
.videoDownload {
  position: absolute;
  Top: 5;
  Left: 5;
  padding:5;
 
}
.player-playlist {
  display: block;
  background-color: black;
  font-family: sans-serif;
}
.player-playlist-item {
  display: flex;
  flex-direction: column;
  background-color: black;
  color: white;
  border-top: 2px solid transparent;
  max-width: 220px;
  margin-bottom: 4px;
}
.player-playlist-item:hover {
  border-top: 2px solid #E20074;
  cursor: pointer;
  background-color: #333;
}
.playlist-item-thumb-row {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  width: 220px;
  overflow: hidden;
}
.playlist-item-thumb {
  position: relative;
  flex: 0 1 191px;
}
.playlist-item-thumb img {
  max-width: 191px;
  height: 107px;
  display: block;
}
.playlist-item-duration {
  display: block;
  display: border-box;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 4px 12px 2px 12px;
  right: 0;
  bottom: 0;
  font-size: 10px;
}
.playlist-item-order {
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  flex: 1 0 auto;
}
.playlist-item-order > span {
  margin-left: auto;
  margin-right: auto;
}
.playlist-item-title {
  padding: 6px 3px;
}
.playlist-item-title h5 {
  margin: 0;
  font-weight: 100;
  font-size: 14px;
  margin-bottom: -4px;
}
.playlist-item-order-active {
  background-color: #E20074;
    color: black;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  flex: 1 0 auto;
}
.playlist-item-order-active > span {
  margin-left: auto;
  margin-right: auto;
}
.autoplay-toggle {
  width:50px;
  height:23px;
  cursor: pointer;
  margin-right: 8px;
}
.autoplay-controller-wrapper {
  padding-top: 8px;
  padding-bottom: 4px;
  position: relative;
  display: flex;
  flex-direction: row;
}
.autoplay-lable {
  margin-right: 4px;
  padding-top: 2px;
}
.player-playlist-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 70vh;
   
    overflow-y: scroll;
  /*max-width: 300px;*/
}

@media (max-width: 997px) {
    .player-playlist-items {
       flex-direction: row;
       max-width: 100%;
    }
    .player-playlist {
       margin-left: 0;
    }
}

@media (min-width: 998px) {
    .player-playlist-items {
       max-width: 240px;
    }
    .player-playlist {
       margin-left: 6px;
    }
}