.playlist-item {
  background-color: black;
  color: white;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 220px 12px auto;
  grid-template-columns: 220px auto;
  grid-column-gap: 12px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: sans-serif;
  font-weight: 100;
  padding: 4px;
  max-width: 983px;
  margin-bottom: 24px;
}
.playlist-item svg {
  fill: white;
}
.playlist-item:hover {
  background-color: #272727;
  cursor: pointer;
}
.more-menu{
  width:50px;
  height:50px;
  float:right;
}
.playlist-metadata {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;  
  -ms-grid-rows: 1fr;
  grid-template-columns:1fr;
  grid-template-rows: 1fr;
  -ms-grid-row-align: start;
      align-self: start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 1.4em;
}
.playlist-thumbnail {
  width: 220px;
  height: 124px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 50% 50%;
  grid-template-columns: 50% 50%;
  -ms-grid-rows: none;
  grid-template-rows: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.playlist-thumbnail img {
  width: 220px;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.playlist-overlay {
  -ms-grid-column: 2;
  grid-column: 2;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: 35% 25% 40%;
  grid-template-rows: 35% 25% 40%;
  background-color: rgba(0, 0, 0, 0.6);
}
.playlist-duration {
  -ms-grid-row: 1;
  grid-row: 1;
  -ms-grid-row-align: end;
      align-self: end;
  -ms-grid-column-align: center;
      justify-self: center;
  display: inline-block;
  font-weight: 100;
  font-size: 15px;
}
.playlist-duration svg, .playlist-share svg, .playlist-header svg, .playlist-edit svg {
  vertical-align: middle;
}
.playlist-videos{
  -ms-grid-row: 2;
  grid-row: 2;
  -ms-grid-row-align: end;
      align-self: end;
  -ms-grid-column-align: center;
      justify-self: center;
  font-weight: 100;
  font-size: 20px;
}
.playlist-icon {
  -ms-grid-row: 3;
  grid-row: 3;
  -ms-grid-row-align: start;
      align-self: start;
  -ms-grid-column-align: center;
      justify-self: center;
}
.playlist-header span, .playlist-share span {
  display: inline-block;
}
.playlist-title {
  font-size: 24px;
  font-weight: 100;
}
div.playlist-duration > svg {
  width: 20px;
  height: 20px;
}
.playlist-header svg, .playlist-icon svg {
  width: 40px;
  height: 40px;
}
.playlist-header svg {
  margin-right: .5em;
}
.playlist-share, .playlist-edit {
  -ms-grid-row: 1;
  grid-row: 1;
  display: inline-block;
  margin-left: 20px;
}
.playlist-details {
  -ms-grid-row: 2;
  grid-row: 2;
  font-size: 14px;
  margin-bottom: 10px;
}
.playlist-description {
  -ms-grid-row: 3;
  grid-row: 3;
  font-size: 14px;
}    
.playlist-description p {
  margin: 0;
  max-width: 600px;
}
.playlist-share:hover, .playlist-share:hover > svg.share-icon, .playlist-edit:hover, .playlist-edit:hover > svg.edit-icon {
  fill: #E20074;
  cursor: pointer;
}
.playlist-share:hover > span, .playlist-edit:hover > span {
  text-decoration: underline;
 }
.playlist-share > svg, .playlist-edit > svg {
  width:27px;
  height:27px;
  margin-right: .5em;
}
div.playlist-divider {
  height: 1px;
  background-color: rgba(255, 206, 10, 0.3);
  margin: 6px 0;
  width: 100%;
}
div.playlist-divider:last-of-type {
  display: none;
}