.sort-wrapper {
    margin: 20px 0px;
    margin-left: 4px;
    color: white;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
span.sort {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    margin-right: 8px;
}
img.sort-date-alpha, img.sort-date-alpha-like-fav {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
}
img.sort-date-alpha:hover, img.sort-date-alpha-like-fav:hover {
    cursor: pointer;
}