.splash-page {
  width: 100%;
  height: 100vh;
  background: url(../images/splash/splash.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /*display: flex;*/
  align-items: center;
  justify-content: center;
  font-family: "Sprint-Sans", sans-serif;
  position:relative;
  overflow: auto;
  overflow: overlay;
}
.splash-modal {

  background-color: white;
  border: 1px dotted #E20074;
  margin-left: 10%;
  margin-right: auto;
  /*display: flex;*/
  flex-direction: column;
  justify-content: center;
  width: 40%;
  max-width: 400px;
  color: black;
  position: absolute;
  top:20%;
}
.splash-modal-info {
  padding: 36px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px dotted #E20074;
  margin-left: 10%;
  margin-right: auto;
  /*display: flex;*/
  flex-direction: column;
  justify-content: center;
  width: 50%;
  max-width: 860px;
  color: white;
}
.splash-error {
  background: url(../images/splash/error.png) no-repeat;
  background-color: white;
  border: 1px dotted #E20074;
  margin-left: 10%;
  margin-right: auto;
  /*display: flex;*/
  flex-direction: column;
  justify-content: center;
  width: 593px;
  height:395px;
  color: black;
  position: absolute;
  top:20%;
}
.splash-error-msg {
  padding: 36px;

  margin-left: 50%;
  margin-right: auto;
  /*display: flex;*/
  flex-direction: column;
  justify-content: center;
  width: 50%;
  max-width: 860px;
}
.splash-modal-header {
  display: block;
  margin-left: 10%;
  background-color: #E20074;
   width: 50%;
  max-width: 860px;
  color: white;
}
.splash-clips-logo {
  display: block;
 background-color: #E20074;
  width: 100%;
  align-self: center;
 padding:30px;
}
.splash-clips-logo2 {
  display: block;
 background-color: #E20074;
  width: 100%;
  align-self: center;
 padding:26px;
}
.splash-su-logo {
  max-width: 300px;
  align-self: center;
  filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, 0.5));
}
.splash-text {
   padding: 20px;
   align-self: center;
}
.splash-text h1 {
  font-weight: 300;
  margin-bottom: 12px;
}
.splash-text p {
  font-weight: 100;
  line-height: 1.5em;
  margin-bottom: 34px;
}
.splash-modal button {
 background-color: #E20074;
  color: white;
  padding: 8px 30px;
  font-size: 16px;
  font-family: arial, sans-serif;
  font-weight: 500;
  margin-left:20px;
   margin-bottom:20px;
  border:none;
  border-radius: 4px;
}

.splash-error button {
 background-color: #E20074;
  color: white;
  padding: 8px 30px;
  font-size: 16px;
  font-family: arial, sans-serif;
  font-weight: 500;
  margin-left:20px;
  margin-bottom:20px;
  border:none;
  border-radius: 4px;
  
}
.splash-modal img {

}
.splash-modal button:hover {
  background-color: #B2005B;
cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .splash-text h1 {
    font-size: 30px;
  }
  .splash-modal {
      width: 40%;
      margin-left: 10%;
  }
}

@media only screen and (max-width: 414px) {
  .splash-text h1 {
    font-size: 25px;
  }
  .splash-modal {
    width: 50%;
    margin-left: 3%;
  }
  .splash-modal {
    padding: 12px;
  }
  .splash-text p {
    margin-bottom: 12px;
  }
  .splash-modal button {
    margin-bottom: 12px;
  }
}

@media only screen and (max-height: 414px) {
  .splash-modal {
    width: 40%;
    margin-left: 10%;
  }
  .splash-modal {
    padding: 12px;
  }
  .splash-text p {
    margin-bottom: 12px;
  }
  .splash-modal button {
    margin-bottom: 12px;
  }
  .splash-clips-logo {
    max-width: 250px;
  }
}




.overlay {
  max-width: 800px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  padding: 5vh;
  backdrop-filter: blur(27px);
  color: white;
  margin-top: 2vh;
  margin-bottom: 5vh;
  text-align: center;
}
.overlay p {
  text-align: center;
  font-family: Helvetica, sans-serif;
  font-size: 4vw;
  line-height: 1.5;
  margin: 20px 0;
  font-size: min(max(calc(12px + 2vw), 4vw), 36px);
}
.logo {
  color: white;
}
.cn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.cw {
  font-family: Helvetica, sans-serif;
  /* font-size: calc(4px + 1vw); */
  /* font-size: min(max(calc(4px + 1vw)), 12px); */
  font-size: min(max(12px, calc(4px + 1vw)), 16px);
  line-height: 1.5;
  color: white;
}
button {
  background-color: white;
  border: 1px solid black;

  padding: 15px 50px;
  padding: calc(8px + 1vw) calc(24px + 3vw);
  font-size: calc(8px + 1vw);
  color: black;
  font-weight: 800;
}
button:hover {
  background-color: #E20074;
  
  color: white;
  cursor: pointer;
}
.logo > svg {
  width: 60vw;
  max-width: 705px;
}
.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.icons > svg {
  height: 5vw;
  min-height: 25px;
  margin: 0 2vw;
}
.icons > svg.podcastIcon {
  height: 7vw;
  min-height: 32px;
  margin: 0 calc(2vw - 3px);
}
/* Portrait */
@media only screen and (max-width: 600px) {
  .overlay {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 0px;
    padding: 5% 10%;
    backdrop-filter: blur(27px);
    color: white;
    text-align: center;
  }
  body {
    padding-left: 0;
    padding-right: 0;
    display: flex;
  }
  .logo {
    width: 100%;
    display:flex;
  }
  .logo > svg {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
/*
@media screen and (min-width: 1160px) {
  .overlay p {
     font-size: 36px;
  }
}
*/