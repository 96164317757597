  body {
  background-color: black;
}
* {
  box-sizing: border-box;
}
.vcard {
  background-color: black;
  font-family: arial, san-serif;
  color: white;
  width: 222px;
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
  margin-right: 3px;
}
.vcardStatic {
  background-color: black;
  font-family: arial, san-serif;
  color: white;
  width: 222px;
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
  margin-right: 3px;
}
.vcard > *, *::before, *::after {
  box-sizing: border-box;
}
.vcard:hover {
  cursor: pointer;
}
.vcard:hover > .vcardMetadataWrapper > .vcardMetadata {
  background-color: #282828;
}
.vcard:hover > .topHover {
  background-color: #E20074;
  border-bottom: 1px solid #E20074;
}
.topHover {
  height: 5px;
  background-color: transparent;
  border-bottom: 1px solid #707070;
}
.vcardThumb {
  border: 1px solid #707070;
  border-top: none;
  position: relative;
}
.vcardThumb img {
  /*width: 220px;
  height: 124px;
   height: 124px;
    width: 220px;*/
    overflow: hidden;
  margin: 0 auto;
  display: block;
}
.videoDuration {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .57);
  font-size: 11px;
  font-weight: 100;
  padding: 4px 6px 1px 8px;
  border-top-left-radius: 10px;
}
.videoDownload {
  position: absolute;
  Top: 5;
  Left: 5;
  padding:5;
 
}
.vcardMetadataWrapper {
  height: 90px;
}
.vcardMetadata {
  padding: 2px 3px 5px 3px;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}
.vcardMetadata h1 {
  font-weight: 100;
  font-size: 12px;
  margin: 0;
}
.videoViewsAge {
  font-size: 12px;
}
h1.videoTitle {
  flex-grow: 1;
  font-size: 15px;
  line-height: 18px;
  max-width: 198px;
  color: #D0D0D0;
  display: inline-block;
  margin-top: 2px;
}
.channelRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.channelTitle {
  color: #D0D0D0;
  font-size: 13px;
  max-width: 198px;
  line-height: 24px;
  font-weight: 100;
  display: inline-block;
}
h1.videoTitle:hover {
  color: white;
}
.videoTitle{
  width: 250px; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
}
.vcard:hover > .vcardMetadataWrapper > .vcardMetadata > h1.videoTitle {
  color: white;
}
.moreChannels {
  fill: #949494;
}
.channelIcon {
  fill: #949494;
  margin-right: 0.5em;
  line-height: 20px;
}
.moreChannels:hover {
  fill: #E20074;
}
.channelTitle:hover {
  color: white;
}
.videoChannel {
  display:inline-block;

}

.videoChannel:hover > .channelIcon svg, .videoChannel:hover > .channelTitle {
  fill: #E20074;
  color: white;
  text-decoration: underline;
  text-decoration-color: #E20074;
}
.videoViewsAge {
  color: #95989A;
}
.vcardMoreButton {
  position: absolute;
  right: 0;
  top: 0;
}
.vcard:hover > .vcardMetadataWrapper > .vcardMetadata > .vcardMoreButton {
  fill: #949494;
}
.vcard:hover > .vcardMetadataWrapper > .vcardMetadata > .vcardMoreButton:hover {
  fill: #E20074;
}
.videoProgressBar {
  height: 3px;
  background-color: #666;
  width: 100%;
}
.videoProgressBarNone {
  background-color: black;
}
.videoProgress {
  height: 100%;
  background-color: #E20074;
  /* Example progress */
  width: 33%;
}
/* Uncomment when there is no progress bar to show. */

.videoProgressBarNone > .videoProgressNone {
  width: 0%;
}
.vcard:hover > .vcardMetadataWrapper > .videoProgressBar {
  background-color: #666;
}
.vcard:hover > .vcardMetadataWrapper > .videoProgressBarNone,
.vcard:hover > .vcardMetadataWrapper > .videoProgressBarNone > .videoProgress {
  background-color: #282828;
}